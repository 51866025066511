<template>
  <div class="project fx-row">

    <div class="project-title fx-col">
      <span>{{ project.name }}</span>
      <div class="project-subtitle">
                    <span v-for="item in project.subtitle" :key="item">
                        {{ item }}
                    </span>
      </div>
      <div class="arrow-next hidden-md" @click="$emit('nextProject')">
        <svg width="55" height="53" viewBox="0 0 55 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.4" filter="url(#filter0_d)">
            <path d="M16 26.5L43 26.5M43 26.5L33.161 17M43 26.5L33.161 36" stroke="white"/>
          </g>
          <defs>
            <filter id="filter0_d" x="0" y="0.640259" width="59.7198" height="51.7194" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset/>
              <feGaussianBlur stdDeviation="8"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.968164 0 0 0 0 0.970833 0 0 0 0 0.837344 0 0 0 0.2 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
          </defs>
        </svg>
      </div>
    </div>

    <div class="statistics">
      <div v-for="item in project.statistics" :key="item.key" class="item">
        <div class="fx-row">
          <span>{{ item.value }}</span>
          <span><div :class="'dot-'+item.color" class="dot-active"></div></span>
        </div>
        <div>{{ item.key }}</div>
      </div>
    </div>

    <div class="description md-50">
      <div class="title hidden-sm">Description</div>
      {{ project.description }}
    </div>

    <div class="competence md-45">
      <div class="title">Competencies</div>
      <div class="fx-row">
        <div v-for="competence in project.competence" :key="competence.icon" class="item fx-row">
          <CompetenceIcon :name="competence.name" :icon="competence.icon" color small full toggle></CompetenceIcon>
        </div>
      </div>
    </div>

    <div class="customers md-50">
      <div class="title">Customers</div>
      <div class="fx-row icons">
        <img class="ui-customer-logo" :src="'/images/partners/'+ item + '.png'" v-for="item in project.customers"
             :key="item" alt/>
      </div>

    </div>

    <div class="tools md-45">
      <div class="title">Technologies & Tools</div>
      <div class="fx-row">
        <div class="ui-tool" v-for="item in project.tools"
             :key="item">
          <img :src="'/images/technologies/'+ item + '.png'" alt data-toggle="tooltip" :title="item"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Project",
  components: {
    CompetenceIcon: () => import("@/components/CompetenceIcon"),
  },
  props: {
    project: {
      name: {type: String, default: ''},
      subtitle: {type: Array},
      description: {type: String, default: ''},
      customers: {type: Array},
      statistics: {type: Array},
      competence: {type: Array},
      tools: {type: Array},
    }
  },
}
</script>

<style scoped lang="scss">
.project {
  justify-content: space-between;
  align-items: stretch;
  margin: 40px 0;
  flex-wrap: wrap;
  @media screen and(max-width: $breakpoint-sm) {
    margin: 0;
    flex-direction: column;
  }
}

.md-50 {
  @media screen and(min-width: $breakpoint-sm+1) {
    width: 50%;
  }
}

.md-45 {
  @media screen and(min-width: $breakpoint-sm+1) {
    width: 45%;
  }
}

.project-title {
  margin-right: auto;
  font-size: 48px;
  line-height: 48px;
  width: 45%;

  @media screen and(max-width: $breakpoint-sm) {
    width: 100%;
    font-size: 24px;
    line-height: 24px;
    padding: 24px 0;
    background: url("/images/project-title-bg-mobile.png") no-repeat;
    background-size: cover;

  }
}

.project-subtitle {
  font-size: initial;
  line-height: initial;
  margin-top: 12px;

  span {
    padding-right: 1em;

    + span {
      padding-left: 1em;
      border-left: 1px solid $white;
    }
  }

  @media screen and(max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    span + span {
      border: none;
      padding-left: 0;
    }
  }
}

.ui-customer-logo {
  width: 60px;
  margin-right: 10px;
}

.arrow-next {
  position: absolute;
  right: 0;
}

.statistics {
  width: 45%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;

  .item {
    display: flex;
    flex-direction: column;

    [class^='dot-'] {
      margin-left: 10px;
    }

    span {
      line-height: 18px;
      font-weight: 500;
    }

    div:last-of-type {
      font-size: 14px;
    }
  }

  @media screen and(max-width: $breakpoint-sm) {
    width: 100%;
    margin-left: unset;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    .item {
      flex-direction: row;

      span {
        margin-right: 10px;

        &:last-of-type {
          order: -1;
        }
      }

      [class^='dot-'] {
        margin-left: unset;
      }
    }
  }
}

.description {
  margin-top: auto;
  align-self: flex-start;
}

.competence {
  align-self: flex-start;

  > .fx-row {
    flex-wrap: wrap;
  }

  .item {
    margin-right: 8px;
    margin-top: 12px;
  }
}

.customers {
  align-self: flex-start;

  .icons {
    flex-wrap: wrap;
  }
}

.tools {
  align-self: flex-start;
}

.ui-tool {
  width: 32px;
  height: 32px;
  margin-right: 8px;

  img {
    display: block;
    margin: 0 auto;
    max-width: 32px;
    max-height: 32px;
    width: auto;
    height: auto;
  }
}

.title {
  margin: 40px auto 12px auto;
}

@media screen and(max-width: $breakpoint-sm) {
  .project-title {
    order: 0;
  }
  .description {
    order: 1;
    padding-top: 40px;
  }
  .statistics {
    order: 2;
  }
  .competence {
    order: 3;
  }
  .tools {
    order: 4;
  }
  .customers {
    order: 5;
  }
}

</style>
