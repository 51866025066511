<template>
  <div class="section-container section-container-statistics" id="pulse">
    <section>
      <div class="section-title-container">
        <div class="section-title">
          <div class="title-number">01</div>
          <div class="title-text">Pulse</div>
        </div>
      </div>

      <div class="section-body fx-col">
        <div class="corner-out"></div>
        <div class="map fx-row">
          <img src="/images/map-statistics.png" class="hidden-xs">
          <img src="/images/map-statistics-mobile.png" class="map-mobile">
          <div class="map-dot project fx-row">
            <div class="dot-pink dot-active"></div>
            <div class="dot-text">12 <span class="hidden-md">active projects</span></div>
          </div>
          <div class="map-dot deployment fx-row">
            <div class="dot-blue dot-active"></div>
            <div class="dot-text">221 <span class="hidden-md">deployments</span></div>
          </div>
          <div class="map-dot commit fx-row">
            <div class="dot-green dot-active"></div>
            <div class="dot-text">834 <span class="hidden-md">commits</span></div>
          </div>
          <div class="map-dot code fx-row">
            <div class="dot-orange dot-active"></div>
            <div class="dot-text">430545 <span class="hidden-md">lines of code</span></div>
          </div>
        </div>
        <div class="corner-out corner-close"></div>
      </div>

      <div class="statistics fx-row hidden-xs">
        <div class="fx-col">
          <div class="corner-in"></div>
          <div class="statistics-item fx-row">
            <div class="dot-green dot-active"></div>
            <div><span>834</span> commits</div>
          </div>
          <div class="corner-in corner-close"></div>
        </div>
        <div class="fx-col">
          <div class="corner-in"></div>
          <div class="statistics-item fx-row">
            <div class="dot-blue dot-active"></div>
            <div><span>221</span> deployments</div>
          </div>
          <div class="corner-in corner-close"></div>
        </div>
        <div class="fx-col">
          <div class="corner-in"></div>
          <div class="statistics-item fx-row">
            <div class="dot-pink dot-active"></div>
            <div><span>12</span> active projects</div>
          </div>
          <div class="corner-in corner-close"></div>
        </div>
        <div class="fx-col">
          <div class="corner-in"></div>
          <div class="statistics-item fx-row">
            <div class="dot-orange dot-active"></div>
            <div><span>430545</span> lines of code</div>
          </div>
          <div class="corner-in corner-close"></div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: "Statistics",
}
</script>

<style scoped lang="scss">

@keyframes blink {
  0% { opacity: 0;}
  50% { opacity: 1;}
}

@keyframes pulse {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.4, 1.4);
    -moz-transform: scale(1.4, 1.4);
    -ms-transform: scale(1.4, 1.4);
    -o-transform: scale(1.4, 1.4);
    transform: scale(1.4, 1.4);
  }
}

.dot-active {
  &.dot-pink {
    animation: pulse 2.5s infinite;
  }
  &.dot-green {
    animation: pulse 1.7s infinite .3s;
  }
  &.dot-blue {
    animation: pulse 1.4s infinite .2s;
  }
  &.dot-orange {
    animation: pulse 2.1s infinite;
  }
  
  &:hover {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
}

.map {
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  .map-dot {
    position: absolute;
  }

  .dot-text {
    margin-left: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    cursor: default;
  }

  .project {
    left: 46.8%;
    top: 14.6%;
    color: $pink;
  }

  .deployment {
    left: 37.3%;
    top: 63.4%;
    color: $blue;
  }

  .code {
    left: 77.3%;
    top: 30.2%;
    color: $orange;
  }

  .commit {
    left: 16.6%;
    top: 30.7%;
    color: $green;
  }
}

.statistics {
  justify-content: center;
  margin-top: 25px;

  > div + div {
    margin-left: 20px;
  }

  .statistics-item {
    margin: 3px 25px;

    span {
      font-weight: 700;
    }

    [class^='dot-'] {
      margin-right: 10px;
    }
  }
}

@media screen and(min-width: $breakpoint-xs + 1) {
  .map-mobile {
    display: none;
  }
}

@media screen and(max-width: $breakpoint-xs) {
  .map {
    width: 100%;

    .dot-text {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      margin-left: unset;
      color: $white;

      span {
        font-weight: 300;
      }
    }

    .project {
      left: 22.5%;
      top: 13.5%;
      color: $pink;

      .dot-text {
        transform: translate(-50%, -24px);
      }
    }

    .deployment {
      left: 54.8%;
      top: 78.7%;
      color: $blue;

      .dot-text {
        transform: translate(-50%, 35px);
      }
    }

    .code {
      left: 20.2%;
      top: 52.9%;
      color: $orange;

      .dot-text {
        transform: translate(-50%, 35px);
      }
    }

    .commit {
      left: 70.7%;
      top: 43.7%;
      color: $green;

      .dot-text {
        transform: translate(-50%, -24px);
      }
    }

  }

}
</style>
