import { render, staticRenderFns } from "./Discipline.vue?vue&type=template&id=13c66977&scoped=true&"
import script from "./Discipline.vue?vue&type=script&lang=js&"
export * from "./Discipline.vue?vue&type=script&lang=js&"
import style0 from "./Discipline.vue?vue&type=style&index=0&id=13c66977&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c66977",
  null
  
)

export default component.exports