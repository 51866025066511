<template>
  <div class="section-container section-container-partners" id="partners">
    <section>
      <div class="section-title-container">
        <div class="section-title">
          <div class="title-number">04</div>
          <div class="title-text">Partners</div>
        </div>
      </div>
      <div class="section-body fx-col">
        <div class="corner-out"></div>
        <div class="partners fx-row">
          <div class="item" v-for="partner in partners" :key="partner">
            <img :src="`/images/partners/${partner}.png`" alt="">
          </div>
        </div>
        <div class="corner-out corner-close"></div>
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return {
      partners: ['spbgpu','itmo', 'iac', 'gpn', 'redflag', 'cml', 'severgroup', 'firmshift', 'wolterskluwer', 'gis', 'odk']
    }
  }
}
</script>

<style scoped lang="scss">

.section-body {
  flex-grow: 1;
  justify-content: space-between;
  background: url("/images/partners-bg.svg") center center no-repeat;
  background-size: contain;
  @media screen and(max-width: $breakpoint-sm) {
    background: none;
    margin-top: 24px;
  }
}

.partners {
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    width: calc((100vw - 185px) / 6);
    height: calc((100vw - 185px) / 6);
    max-width: 212px;
    max-height: 212px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
      max-width: 95px;
      max-height: 95px;
      width: auto;
      height: auto;
    }

    @media screen and(max-width: $breakpoint-xs) {
      width: calc((100vw - 84px) / 3);
      height: calc((100vw - 84px) / 3);

      img {
        max-width: 65%;
        max-height: 65%;
      }
    }
    @media screen and(max-width: $breakpoint-sm) and(min-width: $breakpoint-xs + 1) {
      width: calc((100vw - 84px) / 4);
      height: calc((100vw - 84px) / 4);

      img {
        max-width: 65%;
        max-height: 65%;
      }
    }
  }
}

</style>
